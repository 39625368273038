import achima from './achimaTheme';
import doktorse from './doktorseTheme';
import regionvarmland from './regionvarmlandTheme';
import meliva from './melivaTheme';
import defaultTheme from './defaultTheme';

export const getTheme = () => {

    const theme = window.REACT_APP_THEME;
    switch (theme.trim()) {
        case 'achima':
            return achima;
        case 'doktorse':
            return doktorse;
        case 'regionvarmland':
            return regionvarmland;
        case 'meliva':
            return meliva;
        default:
            return defaultTheme;
    }
}