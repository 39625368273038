import {
    achima as achimaComponentLibraryTheme,
    doktorse as doktorseComponentLibraryTheme
} from "@collabodoc/component-library";
import logo from '../Images/Logo/achima_dark.png'

const achima = {
    ...achimaComponentLibraryTheme,
    logo: logo,
    searchbar: {
        backgroundColor: achimaComponentLibraryTheme.colors.successLight,
        borderColor: achimaComponentLibraryTheme.colors.success
    },
    copybutton: {
        backgroundColor: achimaComponentLibraryTheme.colors.primary,
        borderColor: achimaComponentLibraryTheme.colors.primary
    },
    card: {
        cardHeader: {
            color: 'black'
        }
    },
    sectionHeader:{
        color: 'black'
    },
    typeahead: {
        backgroundColor: achimaComponentLibraryTheme.colors.successLight,
        borderColor: achimaComponentLibraryTheme.colors.success,
        color: 'black'
    },
    patientList: {
        row: {
            hover: {
                backgroundColor: '#AEAEAE',
                color: 'black'
            }
        },
        oddRow: {
            backgroundColor: '#ededed'
        },
        evenRow: {
            backgroundColor: achimaComponentLibraryTheme.colors.white
        },
        pagination: {
            lineHeight: '32px',
            active:{
                borderColor:  achimaComponentLibraryTheme.colors.focus,
                backgroundColor: achimaComponentLibraryTheme.colors.focus,
                color: 'white'
            }
        },
        color: 'black'
    },
    datePicker: {
        hover: {
            backgroundColor: achimaComponentLibraryTheme.colors.tertiary,
            color: achimaComponentLibraryTheme.colors.primary,
        },
        selected: {
            backgroundColor: achimaComponentLibraryTheme.colors.secondary,
            color: achimaComponentLibraryTheme.colors.primary,
        },
        today: {
            backgroundColor: achimaComponentLibraryTheme.colors.primary,
            color: 'white',
        }
    },
    tippyTheme: {
        backgroundColor: achimaComponentLibraryTheme.colors.primary
    }
};

export default achima;


