import {
    meliva as melivaComponentLibraryTheme,
    regionvarmland as regionvarmlandComponentLibraryTheme
} from "@collabodoc/component-library";
import logo from '../Images/Logo/regionvarmland_light.png'

const regionvarmland = {
    ...regionvarmlandComponentLibraryTheme,
    logo: logo,
    searchbar: {
        backgroundColor: '#cce6e0',
        borderColor: regionvarmlandComponentLibraryTheme.colors.success
    },
    copybutton: {
        backgroundColor: regionvarmlandComponentLibraryTheme.colors.primary,
        borderColor: regionvarmlandComponentLibraryTheme.colors.primary
    },
    card: {
        cardHeader: {
            color: 'black',
            fontWeight: 'normal'
        }
    },
    sectionHeader:{
        color: 'black'
    },
    typeahead: {
        backgroundColor: '#cce6e0',
        borderColor: regionvarmlandComponentLibraryTheme.colors.success,
        color: 'black'
    },
    patientList: {
        row: {
            hover: {
                backgroundColor: '#AEAEAE',
                color: 'black'
            }
        },
        oddRow: {
            backgroundColor: '#ededed'
        },
        evenRow: {
            backgroundColor: regionvarmlandComponentLibraryTheme.colors.white
        },
        pagination: {
            lineHeight: '32px',
            active:{
                borderColor:  regionvarmlandComponentLibraryTheme.colors.focus,
                backgroundColor: regionvarmlandComponentLibraryTheme.colors.focus,
                color: 'white'
            }
        },
        color: 'black'
    },
    datePicker: {
        hover: {
            backgroundColor: regionvarmlandComponentLibraryTheme.colors.tertiary,
            color: 'white',
        },
        selected: {
            backgroundColor: regionvarmlandComponentLibraryTheme.colors.secondary,
            color: 'white',
        },
        today: {
            backgroundColor: regionvarmlandComponentLibraryTheme.colors.primary,
            color: 'white',
        }
    },
    tippyTheme: {
        backgroundColor: regionvarmlandComponentLibraryTheme.colors.primary
    }
};

export default regionvarmland;


