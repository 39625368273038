import {
    doktorse as doktorseComponentLibraryTheme,
    meliva as melivaComponentLibraryTheme
} from "@collabodoc/component-library";
import logo from "../Images/Logo/doktorse_light.png"


const doktorse = {
    ...doktorseComponentLibraryTheme,
    logo: logo,
    searchbar: {
        backgroundColor: doktorseComponentLibraryTheme.colors.focusLight,
        borderColor: doktorseComponentLibraryTheme.colors.primary
    },
    copybutton: {
        backgroundColor: doktorseComponentLibraryTheme.colors.success,
        borderColor: doktorseComponentLibraryTheme.colors.success
    },
    card: {
        cardHeader: {
            color: doktorseComponentLibraryTheme.colors.primary
        }
    },
    sectionHeader:{
        color: doktorseComponentLibraryTheme.colors.primary
    },
    typeahead: {
        backgroundColor: doktorseComponentLibraryTheme.colors.focusLight,
        borderColor: doktorseComponentLibraryTheme.colors.primary,
        color: doktorseComponentLibraryTheme.colors.primary
    },
    patientList: {
        row: {
            hover: {
                backgroundColor: doktorseComponentLibraryTheme.colors.success,
                color: 'white'
            }
        },
        oddRow: {
            backgroundColor: doktorseComponentLibraryTheme.colors.tertiary
        },
        evenRow: {
            backgroundColor: doktorseComponentLibraryTheme.colors.white
        },
        pagination: {
            lineHeight: '35px',
            active:{
                backgroundColor: doktorseComponentLibraryTheme.colors.focus,
                color: 'white'
            }
        },
        color: doktorseComponentLibraryTheme.colors.primary
    },
    datePicker: {
        hover: {
            backgroundColor: doktorseComponentLibraryTheme.colors.tertiary,
            color: doktorseComponentLibraryTheme.colors.primary,
        },
        selected: {
            backgroundColor: doktorseComponentLibraryTheme.colors.tertiary,
            color: doktorseComponentLibraryTheme.colors.primary,
        },
        today: {
            backgroundColor: doktorseComponentLibraryTheme.colors.primary,
            color: 'white',
        }
    },
    tippyTheme: {
        backgroundColor: doktorseComponentLibraryTheme.colors.primary
    }
};

export default doktorse;